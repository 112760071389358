import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Weather from '../../components/weather/Weather';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = () => <div><a href="#">BAGEL</a></div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`/*`}<em parentName="p">{` @jsxRuntime classic `}</em>{`/`}</p>
    <p>{`/`}<em parentName="p">{` eslint-disable react/display-name `}</em>{`/
/`}<em parentName="p">{` eslint-disable chai-friendly/no-unused-expressions `}</em>{`/
import React from '@babel/core'`}</p>


    <Weather mdxType="Weather" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      